body {
  margin: 0;
  padding: 0;
  font-stretch: ultra-condensed;
  font-family: "Roboto", sans-serif !important;/*"K2D" ,"Kanit","Helvetica Neue", sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

fieldset {
  padding:0;
  margin:0;
  border:0
}

.datatableWrapper{
  /*border:1px solid #f2f2f2;*/
}

.datatable{
  width:100%;
  border-collapse: collapse;
  border-spacing: 0px;
  table-layout: fixed;
}

.datatable th{
  background-color: rgb(252, 252, 252);
  border:1px solid #f2f2f2;
  font-size:0.7rem;
  font-weight:bold;
  padding:5px 3px;
}

.datatable td{
  border-left:1px solid #f2f2f2;
  border-right:1px solid #f2f2f2;
  border-bottom:1px solid #f2f2f2;
  vertical-align: top;
}

.datatable tfoot td{
  border-left:1px solid #f2f2f2;
  border-right:1px solid #f2f2f2;
  border-bottom:1px solid #f2f2f2;
}

.datatable_print{
  width:100%;
  border-collapse: collapse;
  border-spacing: 0px;
  table-layout: fixed;
}

.datatable_print th{
  background-color: rgb(252, 252, 252);
  border:1px solid black;
  font-size:0.7rem;
  font-weight:bold;
  padding:5px 3px;
}

.datatable_print td{
  border-left:1px solid black;
  border-right:1px solid black;
  vertical-align: top;
}

.datatable_print tfoot td{
  border:1px solid black;
}

.chartTableRowConcreteTruckMonitoring td {
  height: 24px;
  font-size: 11px;
}

.chartTableCellConcreteTruckMonitoring {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chartTableHeaderConcreteTruckMonitoring {
  height: 24px;
  font-size: 11px;
  font-weight: bold;
  background-color: #d9d9d9;
}

.google-visualization-table ::-webkit-scrollbar {
  width: 0px!important;
  height:0px!important;
}

/* Custom Scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: grey;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}

/* Blink */
.blink_me {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0.7;
  }
}

/* Auto Fill Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  opacity:0.7;  /* เพื่อไม่ให้บัง Icon และ Label */
}

.lineclamp2{
  overflow: hidden!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical!important;
}

.csv-button{
  background-color: #2ECC71;
  padding:4px 8px;
  border-radius:4px;
  color:white;
  font-size: 0.7rem;
  box-sizing: border-box;
  line-height:1.75;
  text-decoration:none;
  min-width:64px;
  text-align:center;
  display:block;
  text-transform: uppercase;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%);
}

.ant-picker {
    background-color: #F4EFE7 !important;
}
.ant-select-selector {
    background-color: #F4EFE7 !important;
}
.ant-form-item-control-input {
    background-color: #F4EFE7 !important;
}
